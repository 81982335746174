import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import previewFileAudioComponent from '@/components/PreviewFileAudio'
import previewFileCustomComponent from '@/components/PreviewFileCustom'
import previewFileImageComponent from '@/components/PreviewFileImage'

export default {
    name: 'AlphabetView',
    data() {
        return {
            alphabet: {},
            currentAlphabetId: null,
            fileAudio: null,
            fileSecondAudio: null,
            fileAnimationJsonLeft: null,
            fileAnimationJsonRight: null,
            fileUppercaseBorderImage: null,
            fileUppercaseBorderGuidelinesImage: null,
            fileUppercaseFilledImage: null,
            fileLowercaseBorderImage: null,
            fileLowercaseBorderGuidelinesImage: null,
            fileLowercaseFilledImage: null,
            fileWriteLetterAudio: null,
            fileAlternativeBorderImage: null,
            fileAlternativeGuidelinesImage: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout,
        previewFileAudioComponent,
        previewFileCustomComponent,
        previewFileImageComponent
    },
    mounted() {
        this.currentAlphabetId = this.currentRoute().params.id

        this.getAlphabet()
    },
    methods: {
        getAlphabet() {
            const self = this
            const appends = 'append=originalUppercaseFillColor,originalLowercaseFillColor'

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet/' + self.currentAlphabetId + '?' + appends,
                method: 'GET'
            }).then(function (response) {
                self.alphabet = response.data.alphabet
            })
        },
        deleteAlphabet() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet/' + self.currentAlphabetId,
                method: 'DELETE'
            }).then(function () {
                self.$router.push({ name: 'admin.alphabet' })
            })
        },
        updateAlphabet() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('letter', self.alphabet.letter)
            if (self.fileAudio) formData.append('audio', self.fileAudio)
            if (self.fileSecondAudio) formData.append('second_audio', self.fileSecondAudio)
            if (self.fileAnimationJsonLeft) formData.append('left_animation_json', self.fileAnimationJsonLeft)
            if (self.fileAnimationJsonRight) formData.append('right_animation_json', self.fileAnimationJsonRight)
            if (self.fileWriteLetterAudio) formData.append('write_letter_audio', self.fileWriteLetterAudio)
            if (self.fileAlternativeBorderImage) formData.append('alternative_border_image', self.fileAlternativeBorderImage)
            if (self.fileAlternativeGuidelinesImage) formData.append('alternative_guidelines_image', self.fileAlternativeGuidelinesImage)

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet/' + self.currentAlphabetId,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.alphabet.letter = response.data.alphabet.letter
                self.alphabet.audio = response.data.alphabet.audio
                self.alphabet.second_audio = response.data.alphabet.second_audio
                self.alphabet.left_animation_json = response.data.alphabet.left_animation_json
                self.alphabet.right_animation_json = response.data.alphabet.right_animation_json
                self.alphabet.fileAlternativeBorderImage = response.data.alphabet.alternative_border_image
                self.alphabet.fileAlternativeGuidelinesImage = response.data.alphabet.alternative_guidelines_image

                self.notifySuccess()
            })
        },
        handleFileAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileWriteLetterAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileWriteLetterAudio = null
            }

            this.fileWriteLetterAudio = files[0]
        },
        handleFileSecondAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileSecondAudio = null
            }

            this.fileSecondAudio = files[0]
        },
        handleFileAnimationJsonLeft(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAnimationJsonLeft = null
            }

            this.fileAnimationJsonLeft = files[0]
        },
        handleFileAnimationJsonRight(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAnimationJsonRight = null
            }

            this.fileAnimationJsonRight = files[0]
        },
        handleUppercaseBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileUppercaseBorderImage = null
            }

            this.fileUppercaseBorderImage = files[0]
        },
        handleUppercaseBorderGuidelinesImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileUppercaseBorderGuidelinesImage = null
            }

            this.fileUppercaseBorderGuidelinesImage = files[0]
        },
        handleUppercaseFilledImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileUppercaseFilledImage = null
            }

            this.fileUppercaseFilledImage = files[0]
        },
        handleFileAlternativeBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAlternativeBorderImage = null
            }

            this.fileAlternativeBorderImage = files[0]
        },
        handleAlternativeGuidelinesImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAlternativeGuidelinesImage = null
            }

            this.fileAlternativeGuidelinesImage = files[0]
        },
        updateUppercase() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            if (self.alphabet.original_uppercase_fill_color) formData.append('uppercase_fill_color', self.alphabet.original_uppercase_fill_color)
            if (self.fileUppercaseBorderImage) formData.append('uppercase_border_image', self.fileUppercaseBorderImage)
            if (self.fileUppercaseBorderGuidelinesImage) formData.append('uppercase_border_guidelines_image', self.fileUppercaseBorderGuidelinesImage)
            if (self.fileUppercaseFilledImage) formData.append('uppercase_filled_image', self.fileUppercaseFilledImage)

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet/' + self.currentAlphabetId,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.alphabet.uppercase_border_image = response.data.alphabet.uppercase_border_image
                self.alphabet.uppercase_border_guidelines_image = response.data.alphabet.uppercase_border_guidelines_image
                self.alphabet.uppercase_filled_image = response.data.alphabet.uppercase_filled_image

                self.notifySuccess()
            })
        },
        updateLowercase() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            if (self.alphabet.original_lowercase_fill_color) formData.append('lowercase_fill_color', self.alphabet.original_lowercase_fill_color)
            if (self.fileLowercaseBorderImage) formData.append('lowercase_border_image', self.fileLowercaseBorderImage)
            if (self.fileLowercaseBorderGuidelinesImage) formData.append('lowercase_border_guidelines_image', self.fileLowercaseBorderGuidelinesImage)
            if (self.fileLowercaseFilledImage) formData.append('lowercase_filled_image', self.fileLowercaseFilledImage)

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet/' + self.currentAlphabetId,
                method: 'POST',
                data: formData
            }).then(function (response) {
                self.alphabet.lowercase_border_image = response.data.alphabet.lowercase_border_image
                self.alphabet.lowercase_border_guidelines_image = response.data.alphabet.lowercase_border_guidelines_image
                self.alphabet.lowercase_filled_image = response.data.alphabet.lowercase_filled_image

                self.notifySuccess()
            })
        },
        handleLowercaseBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileLowercaseBorderImage = null
            }

            this.fileLowercaseBorderImage = files[0]
        },
        handleLowercaseBorderGuidelinesImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileLowercaseBorderGuidelinesImage = null
            }

            this.fileLowercaseBorderGuidelinesImage = files[0]
        },
        handleLowercaseFilledImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileLowercaseFilledImage = null
            }

            this.fileLowercaseFilledImage = files[0]
        }
    }
}